import { Link, useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import ArticleCard from "../components/articleCard";
import parse from "html-react-parser";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import BottomBanner from "../components/bottom-banner";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import SvgFanHp from "../components/svg/fan-svg-hp";
import { GatsbyImage } from "gatsby-plugin-image";

const NewsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpArticle(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					id
					slug
					dateGmt(formatString: "MMMM DD, YYYY")
					author {
						node {
							name
						}
					}
					title
					excerpt
					articleFields {
						featureImage {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										quality: 100
										placeholder: BLURRED
										layout: FULL_WIDTH
									)
								}
							}
						}
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "News",
				item: {
					url: `${siteUrl}/news`,
				},
			},
		],
	};

	return (
		<Layout>
			<div style={{ overflow: "hidden" }}>
				<Helmet>
					{" "}
					<script type="application/ld+json">
						{JSON.stringify(breadcrumb)}
					</script>
				</Helmet>

				<GatsbySeo
					title="News | Eastbourne Carnival"
					description="News about Eastbourne Carnival"
					language="en"
					openGraph={{
						type: "website",
						url: `${siteUrl}/news`,
						title: "News | Eastbourne Carnival",
						description: "News about Eastbourne Carnival",
						images: [
							{
								url: `${data.heroImg?.localFile.publicURL}`,
								width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
								height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
								alt: `${data.heroImg?.altText}`,
							},
						],
					}}
				/>

				<section className="pt-5 pb-md-10  position-relative">
					<SvgFanHp
						style={{ zIndex: -1, opacity: 0.1, height: "auto", width: "150%" }}
						className="position-absolute  top-0 start-60 translate-middle-x "
					/>
					<Container>
						<Row className="justify-content-center pb-lg-7 ">
							<Col lg={6}>
								<h1 className=" text-center display-4 pb-4 carnival-normal text-secondary">
									News and Updates
								</h1>
							</Col>
						</Row>
						{data.allWpArticle.nodes
							.filter((item, index) => index === 0)
							.map((article) => (
								<Row
									className="align-items-center"
									style={{ minHeight: "100%" }}
								>
									<Col className="pe-lg-5 pb-4 pb-lg-0  " lg={6}>
										<GatsbyImage
											className="w-100"
											image={
												article.articleFields.featureImage.localFile
													.childImageSharp.gatsbyImageData
											}
											alt={article.articleFields.featureImage.altText}
										/>
									</Col>
									<Col lg={6}>
										<h2 className="shortstack fs-1 text-secondary">
											{article.title}
										</h2>
										<p className="jost-normal text-secondary">
											{article.dateGmt}
										</p>
										<p className="carnival-normal text-primary">
											{article.author.node.name}
										</p>
										<p>{parse(article.excerpt)}</p>
										<a
											className="jost-bold fs-5 link-purple text-decoration-none"
											onClick={() =>
												(window.location = `/news/${article.slug}`)
											}
											href={`#`}
										>
											READ MORE
										</a>
									</Col>
								</Row>
							))}{" "}
						<Row className="pt-md-8 pt-5 pb-5">
							{data.allWpArticle.nodes
								.filter((item, index) => index > 0)
								.map((article) => (
									<ArticleCard
										title={article.title}
										image={
											article.articleFields.featureImage.localFile
												.childImageSharp.gatsbyImageData
										}
										uri={article.slug}
										date={article.dateGmt}
										imageAlt={article.articleFields.featureImage.altText}
										author={article.author.node.name}
									/>
								))}{" "}
						</Row>
					</Container>
				</section>
				<BottomBanner
					mainBg="bg-secondary"
					position="top--20"
					content={
						<Row>
							<Col className="py-5 pb-md-0 mt-md-5 pt-md-10">
								<h2 className="text-white text-center  fs-1 shortstack">
									FOLLOW US ON SOCIAL MEDIA
								</h2>
								<div className="d-flex pt-4 justify-content-center align-items-center">
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.facebook.com/eastbournecarnival"
									>
										<FaFacebookF className="text-white display-2 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://twitter.com/EBCarnival"
									>
										<FaTwitter className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.instagram.com/eastbournecarnival/"
									>
										<FaInstagram className="text-white display-2 ms-5 link-white" />
									</a>
									<a
										rel="noreferrer"
										target="_blank"
										href="https://www.youtube.com/@eastbournecarnival990"
									>
										<FaYoutube className="text-white display-2 ms-5 link-white" />
									</a>
								</div>
							</Col>
						</Row>
					}
				/>
			</div>
		</Layout>
	);
};

export default NewsPage;
